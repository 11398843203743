import React from "react";

const TypeRefLabel: React.FC<{ label: string, customColor: string }> = ({label, customColor}) => {
    if (label) {
        return (
            <span
                className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium border-[1px]`}
                style={{ borderColor: customColor, color: customColor }}>
                {label}
            </span>
        );
    }
    return null;
};

export default TypeRefLabel;

import React, {useEffect} from "react";
import {ButtonType} from "../types/ButtonType";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faClose, faTrash} from "@fortawesome/free-solid-svg-icons";

const Alert: React.FC<{ alertActive: boolean, setAlertActive: (active: boolean) => void, buttonType: ButtonType }> =
    ({
         alertActive,
         setAlertActive,
         buttonType
     }) => {

        useEffect(() => {
            const timer = setTimeout(() => {
                setAlertActive(false);
            }, 7000);

            return () => {
                clearTimeout(timer);
            };
        }, [setAlertActive]);

        const handleClose = () => {
            setAlertActive(false);
        };

        return (
            <>
                {alertActive && (
                    <div
                        role="alert"
                        className="fixed top-0 left-0 right-0 mx-auto w-2/3 z-50 bg-white border border-gray-100 rounded-xl p-4 shadow-md mt-4"
                    >
                        <div className="flex items-start justify-between gap-4">
                        <span>
                            {buttonType === ButtonType.DELETE && (
                                <FontAwesomeIcon className="text-red-600" icon={faTrash}/>
                            )}
                            {buttonType === ButtonType.SAVE && (
                                <FontAwesomeIcon className="text-green-600" icon={faCheck}/>
                            )}
                        </span>

                            <div className="flex-1">
                                <strong className="block font-medium">
                                    {buttonType === ButtonType.DELETE &&
                                        "Advice deleted"
                                    }
                                    {buttonType === ButtonType.SAVE &&
                                        "Advice saved"
                                    }
                                </strong>

                                <p className="mt-1 text-sm">
                                    {buttonType === ButtonType.DELETE &&
                                        "The generated advice got deleted, you can create a new advice by choosing an artifact."
                                    }
                                    {buttonType === ButtonType.SAVE &&
                                        "The generated advice is saved, you can analyze more artifacts or see your advice any time in LeanScope"
                                    }
                                </p>
                            </div>

                            <button className="transition hover:text-gray-600" onClick={handleClose}>
                                <FontAwesomeIcon icon={faClose}/>
                            </button>
                        </div>
                    </div>
                )}
            </>
        );
    };

export default Alert;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {ApolloProvider} from '@apollo/client';
import {GraphQLClient} from './api/apollo/graph-ql.client';
import {BrowserRouter} from "react-router-dom";
import ScrollToTopButton from "./components/ScrollToTopButton";
import SessionTimeChecker from "./components/SessionTimeChecker";

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <React.StrictMode>
        <ApolloProvider client={GraphQLClient}>
            <BrowserRouter>
                <ScrollToTopButton/>
                <SessionTimeChecker/>
            </BrowserRouter>
        </ApolloProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import * as React from 'react';
import FilterSelect from "./FilterSelect";
import {useEffect, useState} from "react";

const CustomTabs: React.FC<{
    selectedScenario: string | null,
    setSelectedScenario: (scenarioTitle: string) => void;
    selectedStepScenario: string | null,
    handleScenarioChange: any,
    handleStepScenarioChange: any,
    availableScenarios: any,
    availableStepScenariosWithArtifacts: any,
    combinedData: any,
    repositoryID: string
}> = ({
          selectedScenario,
          setSelectedScenario,
          selectedStepScenario,
          handleScenarioChange,
          handleStepScenarioChange,
          availableScenarios,
          availableStepScenariosWithArtifacts,
          combinedData,
          repositoryID,
      }) => {
    const [initialStateDone, setInitialStateDone] = useState<boolean>(false);

    const dataPersona = combinedData[0]?.persona;

    useEffect(() => {
        if (!initialStateDone) {
            setSelectedScenario(availableScenarios[0].title);
            setInitialStateDone(true);
        }
    }, [initialStateDone, setSelectedScenario, availableScenarios]);

    return (
        <div
            className="bg-headerGray rounded-xl p-1 sm:flex overflow-hidden items-center sm:mx-16 my-4 space-x-3 mx-4 space-y-2">
            {selectedScenario && (
                <img
                    className="hidden h-10 w-10 rounded-full sm:inline-block"
                    alt="KeyPersona"
                    src={`https://api.leanscope.io/media/${repositoryID}/${dataPersona?.image?.imagePath}.png`}
                />
            )}
            <FilterSelect selectedScenario={selectedScenario} handleScenarioChange={handleScenarioChange}
                          availableScenarios={availableScenarios} filter="headScenario"/>
            <FilterSelect selectedScenario={selectedStepScenario}
                          handleScenarioChange={handleStepScenarioChange}
                          availableScenarios={availableStepScenariosWithArtifacts} filter="stepScenario"/>
        </div>
    );
}

export default CustomTabs;

import React, {useState} from 'react';
import {gql, useQuery} from '@apollo/client';
import {Artifacts} from "./types/Artifacts";
import {artifactsQuery} from "./api/apollo/queries/artifactsQuery";
import {BusinessGoals} from "./types/BusinessGoals";
import {Scenarios} from "./types/Scenarios";
import ArtifactCards from "./components/ArtifactCards";
import PersonaInformation from "./components/PersonaInformation";
import Sources from "./components/Sources";
import {Notes} from "./types/Notes";
import {combineDataHelper} from "./api/apollo/queries/combineData";
import CustomTabs from "./components/CustomTabs";
import Pagination from "./components/Pagination";
import MenuBar from "./components/MenuBar";

const App: React.FC<{ repositoryID: string }> = ({repositoryID}) => {
    const GET_Artifacts = gql(artifactsQuery(repositoryID));
    const {loading, error, data} = useQuery(GET_Artifacts);
    const [selectedScenario, setSelectedScenario] = useState<string | null>(null);
    const [selectedStepScenario, setSelectedStepScenario] = useState<string | null>(null);
    const [selectedJourneyStep, setSelectedJourneyStep] = useState<string | null>(null);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    const businessGoals: BusinessGoals[] = data.uxmData.businessGoals;
    const notes: Notes[] = data.uxmData.notes;

    const combineData = combineDataHelper(data, selectedScenario, selectedStepScenario, selectedJourneyStep);

    const availableScenarios = data.uxmData.scenarios.filter((scenario: Scenarios) => {
        return data.uxmData.artifacts.some((artifact: Artifacts) => {
            const userStory = data.uxmData.userStories.find(
                (story: any) => story.artifacts.some((art: any) => art.artifact === artifact._guid)
            );

            const headScenario = data.uxmData.scenarios.find(
                (sc: Scenarios) => sc._guid === userStory?.originScenarioRef
            );

            return headScenario?.title === scenario.title;
        });
    });

    const availableStepScenarios = availableScenarios
        .filter((scenario: Scenarios) => !selectedScenario || scenario.title === selectedScenario)
        .map((scenario: any) => scenario.scenario?.map((step: any) => step.line))
        .flat();

    const availableStepScenariosWithArtifacts = availableStepScenarios.filter((step: string) => {
        return data.uxmData.artifacts.some((artifact: Artifacts) => {
            const userStory = data.uxmData.userStories.find(
                (story: any) => story.artifacts.some((art: any) => art.artifact === artifact._guid)
            );

            const headScenario = data.uxmData.scenarios.find(
                (sc: Scenarios) => sc._guid === userStory?.originScenarioRef
            );

            const stepScenario = headScenario?.scenario.find(
                (scenario: any) => scenario._guid === userStory?.originScenarioStepRef
            );

            return stepScenario?.line === step;
        });
    });

    const selectedScenarioHelper = data.uxmData.scenarios.find((scenario: Scenarios) => scenario.title === selectedScenario);
    const currentIDScenario = selectedScenarioHelper ? selectedScenarioHelper._guid : null;

    const selectedStepScenarioHelper = selectedScenarioHelper ? selectedScenarioHelper.scenario.find((scenario: any) => scenario.line === selectedStepScenario) : null;
    const currentIDStepScenario = selectedStepScenarioHelper ? selectedStepScenarioHelper._guid : null;

    let filteredUserStories = data.uxmData.userStories.filter((userStory: any) => !selectedScenario || userStory.originScenarioRef === currentIDScenario)
        .filter((userStory: any) => !selectedStepScenario || userStory.originScenarioStepRef === currentIDStepScenario);

    const availableJourneyStep: string[] = Array.from(new Set(filteredUserStories.map((userStory: any) => userStory['title'])));
    availableJourneyStep.unshift("");

    const handleScenarioChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedScenario(event.target.value);
        setSelectedStepScenario("");
        setSelectedJourneyStep("");
    };

    const handleStepScenarioChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedStepScenario(event.target.value);
        setSelectedJourneyStep("");
    };

    return (
        <>
            <MenuBar combinedData={combineData()} repositoryID={repositoryID}/>
            <CustomTabs selectedScenario={selectedScenario} selectedStepScenario={selectedStepScenario}
                        handleScenarioChange={handleScenarioChange} handleStepScenarioChange={handleStepScenarioChange}
                        availableScenarios={availableScenarios}
                        availableStepScenariosWithArtifacts={availableStepScenariosWithArtifacts}
                        combinedData={combineData()} repositoryID={repositoryID} setSelectedScenario={setSelectedScenario}/>

            <div className="flex mt-8 ml-4">
                <div className="w-1/5 mr-8 bg-white min-h-screen rounded-xl flex-shrink-0 sm:block hidden">
                    <PersonaInformation combinedData={combineData()} repositoryID={repositoryID}/>
                </div>
                <div className="sm:w-4/5 overflow-hidden w-full">
                    <Pagination options={availableJourneyStep} handleSelectedJourneyStep={setSelectedJourneyStep}/>
                    <ArtifactCards combinedData={combineData()} notes={notes} goalMarket={businessGoals[0].goalMarket}/>
                </div>
            </div>
            <div className="mt-4 text-sm">
                <Sources businessGoals={businessGoals[0]}/>
            </div>
        </>
    );
};

export default App;

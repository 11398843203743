import React from "react";
import NoteToolTip from "./NoteToolTip";
import TypeRefLabel from "./TypeRefLabel";
import {Notes} from "../types/Notes";
import {faClose} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ModalAdvice: React.FC<{
    artifact: any,
    notes: Notes[],
    setAlertActive: (active: boolean) => void;
    loadingResponse: boolean,
    adviceText: string,
    reAnalyzeText: () => void;
    deleteAdvice: () => void
    saveAdvice: () => void;
}> = ({
          artifact, notes, setAlertActive, loadingResponse, adviceText, reAnalyzeText, deleteAdvice, saveAdvice
      }) => {
    const adviceSentences = adviceText.split(/(Description:|Source:|Advise:)/g);

    return (
        <div
            className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-10 p-4 overflow-y-auto">
            <div className="bg-white shadow-lg rounded-lg p-6 relative">
                <div className="absolute top-0 right-0 m-4 transition hover:text-gray-600"
                     onClick={() => setAlertActive(false)}>
                    <FontAwesomeIcon
                        className={`text-2xl ${loadingResponse ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
                        icon={faClose}
                    />
                </div>
                <div className="relative block overflow-hidden rounded-lg p-4 sm:p-6 lg:p-8 bg-white shadow-md mt-4">
                    <span className="absolute inset-x-0 bottom-0 h-2 bg-menu"/>
                    <div className="sm:flex sm:justify-between sm:gap-4 mb-1">
                        <div>
                            <h3 className="text-lg font-bold text-gray-900 sm:text-xl">
                                <NoteToolTip notes={notes} text={artifact.line}/>
                            </h3>
                        </div>
                    </div>
                    <TypeRefLabel label={artifact.typeRef?.name} customColor={artifact.typeRef?.color}/>
                    <div className="mt-4">
                        <div className="text-pretty text-sm">
                            <NoteToolTip notes={notes} text={artifact.description}/>
                        </div>
                    </div>
                    <dl className="mt-6">
                        <div className="flex flex-col-reverse text-xs">
                            <NoteToolTip notes={notes} text={artifact.userStory.userNeed}/>
                            <dt className="text-base font-light">User Need</dt>
                        </div>
                    </dl>
                    <dl className="mt-6">
                        <div className="flex flex-col-reverse text-xs">
                            <NoteToolTip notes={notes} text={artifact.stepScenario?.line}/>
                            <dt className="text-base font-light">Szenario Schritt</dt>
                        </div>
                    </dl>
                </div>
                <div className="relative block overflow-hidden rounded-lg p-4 sm:p-6 lg:p-8 bg-white shadow-md mt-4">
                    <span className="absolute inset-x-0 bottom-0 h-2 bg-green-800"/>
                    <div className="sm:flex sm:justify-between sm:gap-4 mb-1">
                        <div>
                            <h3 className="text-lg font-bold text-gray-900 sm:text-xl">
                                Advice
                            </h3>
                            {loadingResponse && (
                                <div>Loading ...</div>
                            )}
                            {!loadingResponse && (
                                <div className="text-sm">
                                    {adviceSentences.map((sentence, index) => (
                                        <div key={index}
                                             className={index % 2 === 1 ? '' : 'mb-4 text-xs'}>{sentence.trim()}</div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="relative overflow-hidden bg-white mt-4 flex justify-between">
                    <div>
                        <button
                            className={`group inline-block rounded-full bg-gradient-to-r from-green-400 to-blue-500 p-[2px] ${
                                loadingResponse ? 'opacity-50 cursor-not-allowed' : ''
                            }`}
                            onClick={reAnalyzeText}
                            disabled={loadingResponse}
                        >
                            <span
                                className="block rounded-full bg-white px-4 py-3 text-sm font-medium group-hover:bg-transparent">
                                New Advice
                            </span>
                        </button>
                    </div>
                    <div className="space-x-2 sm:space-x-2">
                        <button
                            className={`group inline-block rounded-full bg-gradient-to-r from-red-400 to-red-600 p-[2px] ${
                                loadingResponse ? 'opacity-50 cursor-not-allowed' : ''
                            }`}
                            onClick={deleteAdvice}
                            disabled={loadingResponse}
                        >
                            <span
                                className="block rounded-full bg-white px-4 py-3 text-sm font-medium group-hover:bg-transparent">
                                Delete Advice
                            </span>
                        </button>
                        <button
                            className={`group inline-block rounded-full bg-gradient-to-r from-green-400 to-blue-500 p-[2px] ${
                                loadingResponse ? 'opacity-50 cursor-not-allowed' : ''
                            }`}
                            onClick={saveAdvice}
                            disabled={loadingResponse}
                        >
                            <span
                                className="block rounded-full bg-white px-4 py-3 text-sm font-medium group-hover:bg-transparent">
                                Save Advice
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalAdvice;

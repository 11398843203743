import React, {Dispatch, useState} from "react";
import TypeRefLabel from "./TypeRefLabel";
import NoteToolTip from "./NoteToolTip";
import {Notes} from "../types/Notes";
import ModalAdvice from "./ModalAdvice";
import {ButtonType} from "../types/ButtonType";
import Alert from "./Alert";
import {mutateArtifactType} from "../api/apollo/mutations/artifactTypeMutation";
import {mutateArtifact} from "../api/apollo/mutations/artifactMutation";
import {mutateUserStories} from "../api/apollo/mutations/userStoriesMutation";
import {ArtifactTypes} from "../types/ArtifactTypes";
import {UserStories} from "../types/UserStories";

const Card: React.FC<{
    artifact: any,
    artifactTypes: ArtifactTypes[],
    userStories: UserStories[],
    goalMarket: string | undefined,
    loadingResponse: boolean,
    setToAnalyseText: Dispatch<string>,
    adviceText: string,
    notes: Notes[],
}> = ({
          artifact,
          artifactTypes,
          userStories,
          goalMarket,
          loadingResponse,
          setToAnalyseText,
          adviceText,
          notes
      }) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [alertActive, setAlertActive] = useState<boolean>(false);
    const [currentButtonType, setCurrentButtonType] = useState<ButtonType>(ButtonType.DEFAULT);

    const handleModalOpen = (artifact: any) => {
        setModalOpen(true);
        setToAnalyseText(`Analyse this idea
        \nArtifact title: ${artifact.line} 
        \nArtifact: ${artifact.description} 
        \nStepScenario: ${artifact.stepScenario?.line} 
        \nUserNeed: ${artifact.userStory?.userNeed}
        \nGoal market: ${goalMarket}`);
    }
    const deleteAdvice = () => {
        setCurrentButtonType(ButtonType.DELETE);
        setAlertActive(true);
        setModalOpen(false);
    }

    const reAnalyzeText = () => {
        if (!loadingResponse) {
            setToAnalyseText(`Analyse this idea
            \nArtifact title: ${artifact.line} 
            \nArtifact: ${artifact.description} 
            \nStepScenario: ${artifact.stepScenario?.line} 
            \nUserNeed: ${artifact.userStory?.userNeed}
            \nGoal market: ${goalMarket}`);
        }
    }

    const saveAdvice = async (userStoryGuid: string, AIAdvice: string) => {
        const mutateArtifactTypeId = await mutateArtifactType(artifactTypes);
        const mutateArtifactId = await mutateArtifact(AIAdvice, mutateArtifactTypeId);
        if (mutateArtifactId) {
            console.log(await mutateUserStories(userStories, mutateArtifactId, userStoryGuid));
        }
        setCurrentButtonType(ButtonType.SAVE);
        setAlertActive(true);
        setModalOpen(false);
    }

    const handleSaveAdvice = async() => {
        await saveAdvice("test", adviceText);
    }

    return (
        <div className="relative block overflow-hidden rounded-lg p-4 sm:p-6 lg:p-8 bg-white shadow-md">
            <span className="absolute inset-x-0 bottom-0 h-2 bg-menu"/>
            <div className="sm:flex sm:justify-between sm:gap-4 mb-1">
                <div>
                    <h3 className="text-lg font-bold text-gray-900 sm:text-xl">
                        <NoteToolTip notes={notes} text={artifact.line}/>
                    </h3>
                </div>
            </div>

            <TypeRefLabel label={artifact.typeRef?.name}
                          customColor={artifact.typeRef?.color}/>

            <div className="mt-4">
                <div className="text-pretty text-sm">
                    <NoteToolTip notes={notes} text={artifact.description}/>
                </div>
            </div>

            <dl className="mt-6">
                <div className="flex flex-col-reverse text-xs">
                    <NoteToolTip notes={notes} text={artifact.userStory.userNeed}/>
                    <dt className="text-base font-light">User Need</dt>
                </div>
            </dl>

            {artifact.typeRef?.name !== 'Sustainability Advisory' && (
                <div className="mt-4">
                    <button
                        className="group inline-block rounded-full bg-gradient-to-r from-green-400 to-blue-500 p-[2px]"
                        onClick={() => handleModalOpen(artifact)}
                        disabled={loadingResponse}
                    >
                <span
                    className="block rounded-full bg-white px-8 py-3 text-sm font-medium group-hover:bg-transparent">
                    Analyze
                </span>
                    </button>
                </div>
            )}
            {modalOpen && (
                <ModalAdvice artifact={artifact} notes={notes} setAlertActive={setAlertActive}
                             loadingResponse={loadingResponse} adviceText={adviceText} reAnalyzeText={reAnalyzeText}
                             deleteAdvice={deleteAdvice} saveAdvice={handleSaveAdvice}/>
            )}
            {alertActive &&
                <Alert alertActive={alertActive} setAlertActive={setAlertActive} buttonType={currentButtonType}/>
            }
        </div>
    )
}

export default Card;
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getUrlParameter } from "../../helper/getUrlParameter";

const GRAPHQL_URI = process.env.REACT_APP_GRAPHQL_URI;

const httpLink = createHttpLink({
    uri: GRAPHQL_URI,
});

const authLink = setContext((_, { headers }) => {
    const userToken = getUrlParameter();
    return {
        headers: {
            ...headers,
            userToken: userToken || '',
        },
    };
});

export const GraphQLClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
});

import React, {useEffect, useState} from 'react';
import {Notes} from "../types/Notes";
import Card from "./Card";
import OpenAI from "openai";

const ArtifactCards: React.FC<{
    combinedData: any,
    goalMarket: string | undefined,
    notes: Notes[],
}> = ({
          combinedData, goalMarket, notes
      }) => {
    const [toAnalyseText, setToAnalyseText] = useState<string>('');
    const [adviceText, setAdviceText] = useState<string>('');
    const [loadingResponse, setLoadingResponse] = useState<boolean>(false);
    const [assistant, setAssistant] = useState<any>(null);
    const [thread, setThread] = useState<any>(null);
    const [openai, setOpenai] = useState<any>(null);

    useEffect(() => {
        initChatBot().then();
    }, []);

    const initChatBot = async () => {
        const openai = new OpenAI({
            apiKey: process.env.REACT_APP_OPENAI_API_KEY,
            dangerouslyAllowBrowser: true,
        });

        const assistantId = process.env.REACT_APP_AI_ASSISTANTID;
        let assistant;

        if (assistantId) {
            assistant = await openai.beta.assistants.retrieve(
                assistantId
            );
            // Create a thread
            const thread = await openai.beta.threads.create();

            setOpenai(openai);
            setAssistant(assistant);
            setThread(thread);
        } else {
            const errorMessage = "No assistantId in .env provided.";
            console.error(errorMessage);
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleSendMessage = async (message: string) => {
        setLoadingResponse(true);

        // Send a message to the thread
        await openai.beta.threads.messages.create(thread.id, {
            role: "user",
            content: message,
        });

        // Run the assistant
        const run = await openai.beta.threads.runs.create(thread.id, {
            assistant_id: assistant.id,
        });

        // Create a response
        let response = await openai.beta.threads.runs.retrieve(thread.id, run.id);

        // Wait for the response to be ready
        while (response.status === "in_progress" || response.status === "queued") {
            await new Promise((resolve) => setTimeout(resolve, 5000));
            response = await openai.beta.threads.runs.retrieve(thread.id, run.id);
        }
        setLoadingResponse(false);

        setAdviceText('');

        // Get the messages for the thread
        const messageList = await openai.beta.threads.messages.list(thread.id);

        // Find the last message for the current run
        const lastMessage = messageList.data
            .filter((message: any) => message.run_id === run.id && message.role === "assistant")
            .pop();

        // Print the last message coming from the assistant
        if (lastMessage) {
            setAdviceText(lastMessage.content[0]["text"].value);
        }
    };

    useEffect(() => {
        if (toAnalyseText !== '') {
            handleSendMessage(toAnalyseText).then();
            setToAnalyseText('');
        }
    }, [handleSendMessage, toAnalyseText]);

    {/*const handleSaveAdvice = async () => {
        setMessages([...messages,
            {
                content: "Save advice",
                isUser: true
            },
            {
                content: "I can generate a different advice for this artifact, if you would like more feedback. \n" +
                    "Or we can continue with a different one. \nJust click on analyse.",
                isUser: false,
            },
        ]);
        setShowSavedAlert(true);
        setBlockButton(false);
        setCurrentState(CurrentState.AnalyseRetry);
        onHandleMutateArtifact(lastChatMessage.userStory._guid, messages[messages.length - 1].content);
    }*/

        if (combinedData) {
            return (
                <div className={`space-y-4 mr-4 ${loadingResponse ? 'overflow-hidden' : ''}`}>
                    {combinedData.map((combinedArtifact: any, index: number) => (
                        <Card artifact={combinedArtifact} artifactTypes={combinedArtifact.artifactTypes}
                              userStories={combinedArtifact.userStories}
                              goalMarket={goalMarket}
                              loadingResponse={loadingResponse}
                              setToAnalyseText={setToAnalyseText}
                              adviceText={adviceText}
                              notes={notes} key={index}
                        />
                    ))}
                </div>
            );
        }
        return null;
    }
};

export default ArtifactCards;
import React, { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { infoPublicSession } from "../api/apollo/queries/infoPublicSession";

const GET_Artifacts = gql(infoPublicSession);

const SessionTime: React.FC = () => {
    const { loading, error, data } = useQuery(GET_Artifacts);
    const [remainingSeconds, setRemainingSeconds] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setRemainingSeconds(calculateRemainingSeconds());
        }, 1000);

        return () => clearInterval(interval);
    });

    if (loading) return <div>Loading ...</div>;
    if (error) return <div>{error.message}</div>;

    const calculateRemainingSeconds = (): number => {
        const currentDate: Date = new Date();
        const expirationDate: Date = new Date(data.infoForPublicDataSession.expireDate);
        const timeDifference: number = expirationDate.getTime() - currentDate.getTime();
        return Math.ceil(timeDifference / 1000);
    };

    const calculateProgress = (): number => {
        const currentDate: Date = new Date();
        const creationDate: Date = new Date(data.infoForPublicDataSession.creationDate);
        const expirationDate: Date = new Date(data.infoForPublicDataSession.expireDate);

        const totalTime: number = expirationDate.getTime() - creationDate.getTime();
        const elapsedTime: number = currentDate.getTime() - creationDate.getTime();

        const percentageElapsed: number = (elapsedTime / totalTime) * 100;
        const percentageRemaining: number = 100 - percentageElapsed;

        return Math.floor(percentageRemaining);
    };

    const circ = 2 * Math.PI * 60;
    const strokePct = ((100 - calculateProgress()) * circ) / 100;

    return (
        <div className="relative w-20 h-20">
            <svg
                className="absolute inset-0 w-full h-full scale-x-[-1]"
                viewBox="0 0 200 200"
                width="200"
                height="200"
            >
                <g transform={`rotate(-90 100 100)`}>
                    <circle
                        r={60}
                        cx={100}
                        cy={100}
                        fill="transparent"
                        stroke={strokePct !== circ ? "green" : ""}
                        strokeWidth="8"
                        strokeDasharray={circ}
                        strokeDashoffset={strokePct}
                        strokeLinecap="round"
                    />
                </g>
            </svg>
            <div className="absolute inset-0 flex justify-center items-center">
                <span className="text-white text-xs font-bold">{Math.ceil(remainingSeconds / 60)} min</span>
            </div>
        </div>
    );
};

export default SessionTime;
import { v4 as uuid } from 'uuid';
import { GraphQLClient } from "../graph-ql.client";
import { gql } from "@apollo/client";
import { getUrlParameter } from "../../../helper/getUrlParameter";
import {ArtifactTypes} from "../../../types/ArtifactTypes";
import {ProcessStepGroups} from "../../../types/ProcessStepGroups";
import {ArtifactTypesMutation} from "../../../types/ArtifactTypesMutation";

const processStepGroupMutation= `
mutation CreateProcessStepGroup($gitLabProjectId: Int!, $sessionGuid: String!, $name: String!, $artifactTypes: [String!], $color: String!, $randomGuid: String!) {
  repository(
    gitLabProjectId: $gitLabProjectId
    sessionGuid: $sessionGuid
    uxmDataInput: {
      processStepGroups: [
        {
          _guid: $randomGuid
          name: $name
          displayname: $name
          artifactTypes: $artifactTypes
          color: $color
          action: CREATE
        }
      ]
    }
  ) {
    processStepGroupsMutated {
      _guid
    }
  }
}`;

const artifactTypeMutation = `
  mutation CreateArtifactType($gitLabProjectId: Int!, $sessionGuid: String!, $randomGuid: String!, $name: String!, $color: String!) {
    repository(
      gitLabProjectId: $gitLabProjectId
      sessionGuid: $sessionGuid
      uxmDataInput: {
        artifactTypes: [
          {
            _guid: $randomGuid
            name: $name
            color: $color
            action: CREATE
          }
        ]
      }
    ) {
      artifactTypesMutated {
        _guid
      }
    }
  }
`;

export const mutateArtifactType = async (allArtifactTypes: ArtifactTypes[]) => {
    try {
        const existingArtifactType = allArtifactTypes.find((type) => type.name === 'Sustainability Advisory');

        if (existingArtifactType) {
            return existingArtifactType._guid;
        }

        let randomGuid = uuid();
        const artifactTypeResponse = await GraphQLClient.mutate<ArtifactTypesMutation>({
            mutation: gql(artifactTypeMutation),
            variables: {
                gitLabProjectId: Number(getUrlParameter()),
                sessionGuid: '52f823af-40b8-401c-a0b9-6a06d1a5af19',
                randomGuid: randomGuid,
                name: 'Sustainability Advisory',
                color: '#007f00',
            },
        });

        const artifactTypeId = artifactTypeResponse.data?.repository?.artifactTypesMutated[0]._guid;
        randomGuid = uuid();
        await GraphQLClient.mutate<ProcessStepGroups>({
            mutation: gql(processStepGroupMutation),
            variables: {
                gitLabProjectId: Number(getUrlParameter()),
                sessionGuid: '52f823af-40b8-401c-a0b9-6a06d1a5af19',
                randomGuid: randomGuid,
                artifactTypes: [artifactTypeId],
                name: 'Sustainability Advisory',
                color: '#007f00',
            },
        });

        return artifactTypeId;
    } catch (error) {
        console.error(error);
        return '';
    }
};

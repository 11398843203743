import {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronUp} from "@fortawesome/free-solid-svg-icons";


const ScrollToTopButton = () => {
    const [shown, setShown] = useState(false);

    useEffect(() => {
        const scrollCallback = () => {
            const scrolledFromTop = window.scrollY;
            setShown(() => scrolledFromTop > 300);
        };
        window.addEventListener('scroll', scrollCallback);
        scrollCallback();
        // clean-up function
        return () => {
            window.removeEventListener('scroll', scrollCallback);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <button
            aria-label='scroll to top'
            onClick={scrollToTop}
            className={`${
                shown ? 'scale-100' : 'scale-0'
            } w-12 h-12 transition-transform duration-200 flex fixed right-10 bottom-10 rounded-full shadow-lg justify-center items-center z-10 bg-menu text-white`}
        >
            <FontAwesomeIcon icon={faChevronUp}/>
        </button>
    );
};
export default ScrollToTopButton;
import React from 'react';
import {Notes} from "../types/Notes";

const NoteToolTip: React.FC<{ notes: Notes[], text: string }> = ({notes, text}) => {
    const extractIdFromHtml = (htmlString: string) => {
        const doc = new DOMParser().parseFromString(htmlString, 'text/html');
        const spanElement = doc.querySelector('span.annotation');

        if (spanElement) {
            const id = spanElement.getAttribute('id');
            return id || null;
        }

        return null;
    };

    const findNoteById = (extractedId: string | null | undefined) => {
        return notes.find(note => note._guid === extractedId);
    };

    const filterText = (text: string | undefined) => {
        if (typeof text === "string") {
            const doc = new DOMParser().parseFromString(text, 'text/html');
            const decodedText = doc.body.textContent || "";
            return decodedText.trim();
        }
        return '';
    };

    return (
        <div className="group flex relative">
            <div>
                {filterText(text)}
                {filterText(findNoteById(extractIdFromHtml(text))?.text) ? (
                    <span className="group-hover:opacity-100 transition-opacity bg-gray-800 text-sm
                        opacity-0 rounded text-white p-4 font-normal ml-2 inset-x-0 bottom-0 sm:inline hidden">
                        {filterText(findNoteById(extractIdFromHtml(text))?.text)}
                    </span>
                ) : null}
            </div>
        </div>
    );
};

export default NoteToolTip;

import React from "react";
import {Scenarios} from "../types/Scenarios";

const FilterSelect: React.FC<{
    selectedScenario: string | null,
    handleScenarioChange: any,
    availableScenarios: any,
    filter: "headScenario" | "stepScenario"
}> = ({selectedScenario, handleScenarioChange, availableScenarios, filter}) => {
    return (
        <div className="sm:w-1/2 w-[92%]">
            <label className="block text-sm font-medium ml-1"> {filter === "headScenario" ? "Szenario" : "Szenario Schritt"} </label>
            <select
                id={filter}
                name={filter}
                value={selectedScenario || ""}
                onChange={handleScenarioChange}
                className="w-full rounded-lg border-gray-300 bg-headerGray hover:bg-gray-600 text-white sm:text-lg h-8 focus-visible:bg-headerGray outline-none mb-2"
            >
                {filter !== "headScenario" && <option value="">All</option>}
                {filter === "headScenario" &&
                    availableScenarios.map((scenario: Scenarios) => (
                        <option key={scenario._guid} value={scenario.title}>
                            {scenario.title}
                        </option>
                    ))
                }
                {filter === "stepScenario" &&
                    availableScenarios.map((stepScenario: string) => (
                        <option key={stepScenario} value={stepScenario}>
                            {stepScenario}
                        </option>
                    ))
                }
            </select>
        </div>
    );
}

export default FilterSelect;

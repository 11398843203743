import React, {useState} from 'react';
import Logo from "../assets/Logo.LeanScopeWhite.svg";
import {useNavigate} from "react-router-dom";
import PersonaInformation from "./PersonaInformation";
import SessionTime from "./SessionTime";

const MenuBar: React.FC<{ combinedData?: any | null, repositoryID: string }> = ({combinedData, repositoryID}) => {
    const [openMenu, setOpenMenu] = useState<boolean>(false);
    const navigate = useNavigate();

    const handleOpenMenu = () => {
        setOpenMenu(!openMenu);
    }

    const handleButtonLogo = () => {
        navigate("/");
        window.location.reload();
    };

    return (
        <div className="bg-menu">
            <div className="px-4">
                <div className="flex justify-between items-center py-2 pb-1">
                    <img
                        src={Logo}
                        alt="LeanScope Logo"
                        onClick={handleButtonLogo}
                        className="cursor-pointer w-12 h-12"
                    />
                    {combinedData && (
                        <div className="relative">
                            <div className="flex items-center">
                                <SessionTime/>
                                <img
                                    className="block h-10 w-10 rounded-full cursor-pointer sm:hidden"
                                    alt="KeyPersona"
                                    src={`https://api.leanscope.io/media/${repositoryID}/${combinedData[0]?.persona?.image?.imagePath}.png`}
                                    onClick={handleOpenMenu}
                                />
                            </div>
                            {openMenu && (
                                <div
                                    className="absolute right-0 top-12 z-10 mt-2 w-80 rounded-md border border-gray-100 bg-white shadow-lg"
                                    role="menu"
                                >
                                    <div
                                        className="relative block overflow-hidden rounded-lg pb-4 lg:p-8 bg-white shadow-md">
                                        <span className="absolute inset-x-0 bottom-0 h-2 bg-menu"/>
                                        <PersonaInformation combinedData={combinedData}
                                                            handleOpenMenu={handleOpenMenu}
                                                            repositoryID={repositoryID}/>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MenuBar;

import React, {useEffect, useState} from 'react';
import {filterText} from "../helper/filterText";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight, faChevronLeft} from '@fortawesome/free-solid-svg-icons';

const Pagination: React.FC<{
    options: string[],
    handleSelectedJourneyStep: React.Dispatch<string | null>
}> = ({options, handleSelectedJourneyStep}) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        handleSelectedJourneyStep(options[currentIndex]);
    }, [currentIndex, handleSelectedJourneyStep, options]);

    const handlePrevious = () => {
        setCurrentIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : options.length - 1));
    };

    const handleNext = () => {
        setCurrentIndex(prevIndex => (prevIndex < options.length - 1 ? prevIndex + 1 : 0));
    };

    return (
        <>
            <div className="flex items-center justify-between mr-4 mb-4">
                <button
                    className="px-3 py-1 bg-gray-200 rounded-xl flex-shrink-0"
                    onClick={handlePrevious}
                >
                    <FontAwesomeIcon icon={faChevronLeft}/> Previous
                </button>
                <div className="mx-20 truncate text-xl font-bold sm:block hidden bg-gray-200 rounded-xl px-3">
                    {filterText(options[currentIndex]) ? filterText(options[currentIndex]) : "All"}
                </div>
                <button
                    className="px-3 py-1 bg-gray-200 rounded-xl flex-shrink-0"
                    onClick={handleNext}
                >
                    Next <FontAwesomeIcon icon={faChevronRight}/>
                </button>
            </div>
            <div className="mr-4 text-center my-4 font-bold bg-gray-200 rounded-xl truncate sm:hidden">
                {filterText(options[currentIndex]) ? filterText(options[currentIndex]) : "All"}
            </div>
        </>
    );
};

export default Pagination;
